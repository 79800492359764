import React from "react";
import { Link } from "gatsby";
import logoWhite from "../../../img/logo-white.svg";
import HubspotForm from "react-hubspot-form";

export const FooterMain = ({
  socials_title,
  facebook,
  twitter,
  instagram,
  linkedin,
  footer_menu_1_title,
  footer_menu_1_links,
  footer_menu_2_title,
  footer_menu_2_links,
  footer_contact_title,
  footer_address,
  footer_email,
  footer_phone,
  footer_company_vat,
  portalId,
  formId,
  newsletterTitle,
  newsletterContent,
}) => {
  return (
    <footer className="page-footer font-small bg-darker-primary">
      <section className="bg-secondary">
        <div className="container-fluid">
          <div className="row contained-xl py-4 justify-content-center align-items-center">
            <div className="col-md-6 col-lg-3 text-left text-md-left mb-4 mb-md-0">
              {newsletterTitle && (
                <h2 className="h5 mb-2">{newsletterTitle}</h2>
              )}
              {newsletterContent && (
                <p className="small">{newsletterContent}</p>
              )}
              <div id="hubspotFormFooter">
                <HubspotForm
                  portalId={portalId}
                  formId={formId}
                  loading={<div></div>}
                />
              </div>
              {/* <div className="hubspot_recaptcha">
                Protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
                apply.
              </div> */}
            </div>
            {/* )} */}
            <div
              className={`col-md-6 col-lg-5 offset-lg-2 text-center text-md-right`}
            >
              {socials_title && <h2 className="h5 mb-2">{socials_title}</h2>}
              <a
                href={linkedin}
                rel="noreferrer"
                target="_blank"
                className="mr-4"
              >
                <i className="fab fa-linkedin-in white-text"></i>
                <span className="sr-only">LinkedIn</span>
              </a>

              <a
                href={twitter}
                rel="noreferrer"
                target="_blank"
                className="mr-4"
              >
                {/* <i className="fa fa-twitter white-text"></i>
                <span className="sr-only">X</span> */}
                <div class="x-icon"></div>
              </a>
              <a
                href={facebook}
                rel="noreferrer"
                target="_blank"
                className="mr-4"
              >
                <i className="fab fa-facebook-f white-text"></i>
                <span className="sr-only">Facebook</span>
              </a>
              <a
                href={instagram}
                rel="noreferrer"
                target="_blank"
                className="mr-4"
              >
                <i className="fab fa-instagram white-text"></i>
                <span className="sr-only">Instagram</span>
              </a>

              <a
                href="https://www.youtube.com/channel/UCzJTvbZBJJunOXFD1_CCImg"
                rel="noreferrer"
                target="_blank"
                className="mr-4"
              >
                <i className="fab fa-youtube white-text"></i>
                <span className="sr-only">Youtube</span>
              </a>
              <a
                href="http://bit.ly/AppleB2C"
                rel="noreferrer"
                target="_blank"
                className="mr-4"
              >
                <i className="fab fa-apple white-text"></i>
                <span className="sr-only">Apple Podcasts</span>
              </a>
              <a
                href="https://open.spotify.com/show/3WdHP5DkccckZktUun2MmG"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fab fa-spotify white-text"> </i>
                <span className="sr-only">Spotify</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="container-fluid text-center text-md-left mt-5"
        data-aos="fade-in"
      >
        <div className="row contained-xl justify-content-center mt-3 py-4">
          <div className="col-lg-10">
            <div className="row">
              <div className="col-md-3 col-lg-4 mb-4">
                <img
                  src={logoWhite}
                  alt="Databowl"
                  className="img-fluid brand mb-4"
                />
              </div>

              <div className="col-6 col-md-2 col-lg-2 mb-4 offset-lg-1">
                <div className="h6 text-uppercase font-weight-bold">
                  {footer_menu_1_title}
                </div>
                <hr className="border col-1 border-secondary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                {footer_menu_1_links &&
                  footer_menu_1_links.map(function (items, i) {
                    return (
                      <p key={"footer_menu_1_link" + i}>
                        <Link
                          cover={`true`}
                          direction="right"
                          bg="#1F1A3C"
                          to={items.url}
                        >
                          {items.title}
                        </Link>
                      </p>
                    );
                  })}
              </div>

              <div className="col-6 col-md-3 col-lg-2 mb-4">
                <div className="h6 text-uppercase font-weight-bold">
                  {footer_menu_2_title}
                </div>
                <hr className="border col-1 border-secondary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                {footer_menu_2_links &&
                  footer_menu_2_links.map(function (items, i) {
                    return (
                      <p key={"footer_menu_2_link" + i}>
                        <Link
                          cover={`true`}
                          direction="right"
                          bg="#1F1A3C"
                          to={items.url}
                        >
                          {items.title}
                        </Link>
                      </p>
                    );
                  })}
              </div>

              <div className="col-md-4 col-lg-3 mt-4 mt-md-0 mb-md-0 mb-4 text-center text-md-right ">
                <div className="h6 text-uppercase font-weight-bold">
                  {footer_contact_title}
                </div>
                <hr className="border col-1 border-secondary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                <div className="small">
                  <p>
                    <i className="fas fa-map-marker-alt mr-2"></i>
                    {footer_address}
                  </p>
                  <p>
                    <i className="fas fa-envelope mr-2"></i>
                    <a href={"mailto:" + footer_email}>{footer_email}</a>
                  </p>
                  <p>
                    <i className="fas fa-phone mr-2"></i>
                    <a href={"tel:" + footer_phone}>{footer_phone}</a>
                  </p>
                  <p>
                    <i className="fas fa-print mr-2"></i> {footer_company_vat}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright text-center pt-3 pb-4 small">
          © {new Date().getFullYear()}
          <a href="https://www.databowl.com/" className="ml-2">
            www.databowl.com
          </a>
        </div>
      </section>
    </footer>
  );
};

export default FooterMain;
