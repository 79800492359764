import React from "react";
import HubspotForm from "react-hubspot-form";

export const Newsletter = ({ title, content, color, portalId, formId }) => {
  return (
    <section className="newsletter">
      <div className={`bg-${color} ${title ? "py-5" : "pb-5"}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 text-center text-md-left mb-4 mb-md-0">
              {title && <h2 className="mb-2 text-center ">{title}</h2>}
              {content && <p className="text-center">{content}</p>}
              <div className="row justify-content-center">
                <div
                  className={`col-md-10 col-lg-8 text-center text-md-left mb-4 mb-md-0 ${
                    title ? "py-4" : "pb-lg-5"
                  }`}
                >
                  <div id="hubspotForm" className="text-center">
                    <HubspotForm
                      portalId={portalId}
                      formId={formId}
                      loading={<div>Loading newsletter form...</div>}
                    />
                  </div>
                  {/* <div class="hubspot_recaptcha">
                    Protected by reCAPTCHA and the Google{" "}
                    <a href="https://policies.google.com/privacy">
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a href="https://policies.google.com/terms">
                      Terms of Service
                    </a>{" "}
                    apply.
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
