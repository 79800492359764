import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export const Stats = ({ statsTitle, statsIntro, stats }) => {
  // var active = [0, 1, 0, 0]
  var duration = [0.5, 1, 1.25, 2];
  var delay = [0, 0.25, 0.5, 1];
  var suffix = ["", "", "+", ""];
  var suffixComplete = ["", "", "+", ""];
  function statComplete(i) {
    // console.log(i, suffix, suffix[i], suffixComplete[i])
    suffix[i] = suffixComplete[i];
  }
  return (
    <section id="stats" className="text text-center container-fluid py-5">
      {statsTitle && (
        <React.Fragment>
          <h2>{statsTitle}</h2>
          <p className="lead">{statsIntro}</p>
        </React.Fragment>
      )}
      <div className="row justify-content-center pt-5">
        <div className="col-lg-8 col-md-10">
          <div className="row">
            {stats.map(function (stat, i) {
              return (
                <div className="col-6 col-sm-3 mb-3" key={"stat" + i}>
                  <div className="h2 text-secondary">
                    <CountUp
                      // duration={duration[i]}
                      // start={1}
                      // end={parseInt(stat.stat.replace(/\D/g, ""))}
                      // separator=","
                      // suffix={suffix[i]}
                      // prefix={i === 3 ? `£` : ""}
                      // delay={delay[i]}
                      // onEnd={statComplete(i)}
                      // Conditionally Add £ or +:
                      // If the stat contains +, add it as a suffix.
                      // If the stat contains £, add it as a prefix.
                      // This logic ensures that only the necessary formatting appears.
                      duration={duration[i]}
                      start={1}
                      end={parseInt(stat.stat.replace(/\D/g, ""))}
                      separator=","
                      suffix={stat.stat.includes("+") ? "+" : ""}
                      prefix={stat.stat.includes("£") ? "£" : ""}
                      delay={delay[i]}
                      onEnd={() => statComplete(i)}
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor
                          onChange={start}
                          delayedCall={true}
                          offset={200}
                        >
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </div>
                  <div>
                    {stat.subtitle_1 && (
                      <div className="h5">{stat.subtitle_1}</div>
                    )}
                    {stat.subtitle_2 && (
                      <div className="h6">{stat.subtitle_2}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
