import React, { useState } from "react";
import useSiteMetadata from "./SiteMetadata";
import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader";

import FooterMain from "../components/General/Footer/Main";
import Awards from "../components/General/Footer/Awards";
import Rescue from "../components/General/Footer/Rescue";

import Navbar from "../components/General/NavbarNew";

import CookieConsent from "react-cookie-consent";
import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

import "./../scss/style.scss";
import { Link } from "gatsby";

const TemplateWrapper = ({
  children,
  backgroundColour,
  darkMenu,
  accentColour,
  hideNav,
  hideFooter,
  hideIntercom,
}) => {
  const { meta, awards, social, rescue, alert, contact } = useSiteMetadata();
  const location = useLocation();
  const [openCookiePreferences, setOpenCookiePreferences] = useState(false);

  return (
    <React.Fragment>
      <LiveChatLoaderProvider providerKey="lkfwwyuq" provider="intercom">
        {openCookiePreferences && (
          <aside className="cookie-consent p-4 p-lg-5" style={{ bottom: 0 }}>
            <div className="">
              <h2 className="h4">Cookie Preferences</h2>
              <div className="row">
                <div className="col-12">
                  <h3 className="h6">Advertising</h3>
                </div>
                <div className="col-12">
                  <p className="small">
                    These cookies are used to make advertising messages more
                    relevant to you. They perform functions like preventing the
                    same ad from continuously reappearing, ensuring that ads are
                    properly displayed for advertisers, and in some cases
                    selecting advertisements that are based on your interests.
                  </p>
                </div>
                <div className="col-3">
                  {/* <button className="btn btn-sm btn-outline-primary w-100 d-block">Turn Off</button> */}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h3 className="h6">Analytics and customization</h3>
                </div>
                <div className="col-12">
                  <p className="small">
                    These cookies collect information that is used either in
                    aggregate form to help us understand how our Website is
                    being used or how effective our marketing campaigns are, or
                    to help us customize our Website for you.
                  </p>
                </div>
                <div className="col-12">
                  <p className="small">
                    <Link to="/cookies/">
                      Learn More about our cookie policy.
                    </Link>
                  </p>
                </div>
                <div className="col-3">
                  {/* <button className="btn btn-sm btn-outline-primary w-100 d-block">Turn Off</button> */}
                </div>
              </div>
            </div>

            <CookieConsent
              disableStyles={true}
              flipButtons
              buttonText="Accept All"
              declineButtonText="Decline All"
              containerClasses="row"
              buttonWrapperClasses="col-12"
              buttonClasses="btn btn-sm btn-secondary w-100 w-md-auto d-block d-lg-inline-block mb-2 mt-3 mt-md-0"
              declineButtonClasses="btn btn-sm btn-outline-primary w-100 w-md-auto d-block d-lg-inline-block mt-2 mb-2 mt-md-0"
              overlayClasses=""
              cookieName="cookie-consent"
              enableDeclineButton
              onDecline={() => {
                setOpenCookiePreferences(false);
              }}
              onAccept={() => {
                console.log(location);
                setOpenCookiePreferences(false);
                initializeAndTrack(location);
              }}
            ></CookieConsent>
          </aside>
        )}
        <CookieConsent
          disableStyles={true}
          flipButtons
          location="bottom"
          buttonText="Accept"
          declineButtonText="Preferences"
          onDecline={() => {
            setOpenCookiePreferences(true);
          }}
          containerClasses="cookie-consent row"
          contentClasses="col-md-8 col-lg-8"
          buttonWrapperClasses="col col-lg-4 text-right pt-2"
          buttonClasses="btn btn-sm btn-secondary mb-2 mt-3 mt-md-0 mr-2"
          declineButtonClasses="btn btn-sm btn-outline-primary mt-2 mb-2 mt-md-0"
          overlayClasses=""
          expires={150}
          setDeclineCookie={false}
          cookieName="cookie-consent"
          acceptOnScroll={true}
          acceptOnScrollPercentage={33}
          enableDeclineButton
          onAccept={() => {
            console.log(location);
            initializeAndTrack(location);
          }}
        >
          <span className="mr-1">🍪</span> We use marketing cookies to enhance
          your user experience. If you're OK with this just scroll or click accept. <Link to="/cookies/">Learn More.</Link>
        </CookieConsent>

        {!hideNav && (
          <Navbar
            darkMenu={darkMenu}
            alert={alert}
            social={social}
            contact={contact}
          />
        )}
        <article
          className={
            `bg-` +
            backgroundColour +
            (accentColour ? ` accent-color-` + accentColour : "")
          }
        >
          {children}
        </article>

        {!hideFooter && (
          <React.Fragment>
            <Rescue
              image={rescue.background_image}
              hello={rescue.title1}
              title={rescue.title2}
              intro={rescue.intro}
              link={rescue.link}
            />

            <Awards awardsIntro={awards.awards_title} awards={awards.awards} />
            <FooterMain
              socials_title={social.title}
              facebook={social.facebook}
              twitter={social.twitter}
              instagram={social.instagram}
              linkedin={social.linkedin}
              footer_menu_1_title={meta.footer_1_title}
              footer_menu_1_links={meta.footer_menu}
              footer_menu_2_title={meta.footer_2_title}
              footer_menu_2_links={meta.footer_menu_2}
              footer_contact_title={contact.contact_title}
              footer_address={contact.address}
              footer_email={contact.email}
              footer_phone={contact.phone}
              footer_company_vat={contact.comp_vat}
              portalId={social.footerNewsletterPortalId}
              formId={social.footerNewsletterFormId}
              newsletterTitle={social.footerNewsletterTitle}
              newsletterContent={social.footerNewsletterContent}
            />
          </React.Fragment>
        )}
        {!hideIntercom && <Intercom idlePeriod={1000} color="#f43e70" />}
      </LiveChatLoaderProvider>
    </React.Fragment>
  );
};

export default TemplateWrapper;
