import * as React from "react";
import ImageGallery from "react-image-gallery";
// import "/node_modules/react-image-gallery/styles/css/image-gallery.css";

const ImageSlideshow = ({ cmsPreview = false, title, images }) => {
  var formattedImages = [];
  if (cmsPreview) {
    images &&
      images?.forEach((image) => {
        if (image?.image)
          formattedImages.push({
            original: image.image.replace("static/", "/"),
            // description: image.caption,
          });
      });
  } else {
    images &&
      images?.forEach((image) => {
        if (
          image?.image?.childImageSharp?.gatsbyImageData?.images?.fallback
            ?.srcSet
        ) {
          formattedImages.push({
            originalAlt: image.caption ? image.caption : title + "Image",
            loading: "lazy",
            srcSet:
              image.image.childImageSharp.gatsbyImageData.images.fallback
                .srcSet,
            // description: image.caption,
          });
        }
      });
  }
  return (
    <>
      <ImageGallery
        items={formattedImages}
        showThumbnails={false}
        showPlayButton={false}
        lazyLoad={true}
        startIndex={0}
        showFullscreenButton={false}
        infinite={true}
        // renderFullscreenButton={(onClick, isFullscreen) => (
        //   <div
        //     className="image-gallery-icon image-gallery-fullscreen-button"
        //     onClick={onClick}
        //     isFullscreen={isFullscreen}
        //   >
        //     <i className="fa fa-expand h1" />
        //   </div>
        // )}
        renderRightNav={(onClick, disabled) => (
          <div
            className="image-gallery-icon image-gallery-right-nav"
            onClick={onClick}
            disabled={disabled}
          >
            <i className="fa fa-chevron-right h1 mr-2" />
          </div>
        )}
        renderLeftNav={(onClick, disabled) => (
          <div
            className="image-gallery-icon image-gallery-left-nav"
            onClick={onClick}
            disabled={disabled}
          >
            <i className="fa fa-chevron-left h1 ml-2" />
          </div>
        )}
        // renderLeftNav={(onClick, disabled) => (
        //   <div onClick={onClick} disabled={disabled}>RIGHT NAv</div>
        // )}
      />
    </>
  );
};

export default ImageSlideshow;
