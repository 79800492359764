import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SuccessCard from "../components/Success/SuccessCard";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";

const SuccessTemplate = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;
  const { meta, social } = useSiteMetadata();

  return (
    <Layout backgroundColour="light">
      <Seo
        title={
          meta.successName +
          (pageContext.pageNumber > 0
            ? meta.sitePageTitleSep +
              "Page " +
              (pageContext.pageNumber + 1) +
              " of " +
              pageContext.numberOfPages
            : "") +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={
          meta.siteUrl +
          "/case-studies/" +
          (pageContext.pageNumber ? "page/" + (pageContext.pageNumber + 1) : "")
        }
        description={meta.successDescription}
        image={meta.siteImage}
        twitterTitle={meta.siteTitle + " " + meta.successName}
        facebookTitle={meta.siteTitle + " " + meta.successName}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />

      <header className="hero container-fluid pt-5 mt-5">
        <div className="row justify-content-center text-center">
          <div className="col-md-6 col-xl-4 mb-md-5 pt-lg-5">
            <h1>Case Studies</h1>
            <div className="lead">
              Our clients are our best advocates, so there's no better way to
              share our success than through their success.
            </div>
          </div>
        </div>
      </header>

      <section className="postlists container-fluid">
        <div className="row justify-content-center mt-3">
          <div className="col-md-11 col-lg-10">
            <div className="row pb-5 contained-xl">
              {posts &&
                posts.map(({ node: post }, index) => (
                  <div className="col-md-6 col-lg-4 mb-4" key={`card_` + index}>
                    {/* {JSON.stringify(post)} */}
                    <SuccessCard
                      image={post.frontmatter.featuredimage}
                      title={post.frontmatter.title}
                      date={post.frontmatter.date}
                      excerpt={post.frontmatter.excerpt}
                      slug={post.frontmatter.path}
                      readtime={``}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SuccessTemplate;

export const SuccessListQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fields: { slug: { regex: "//success//" } }
        frontmatter: { hideSEO: { ne: true }, unpublished: { ne: true }  }
      }
      sort: { order: ASC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            excerpt
            metaDescription
            path
            featuredimage {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            metaImage {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
