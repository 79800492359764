import React from "react";
import Image from "../Hacks/Image";

// import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

//import Fade from "react-reveal/Fade";
// import Pulse from "react-reveal/Pulse";

// import { AttentionSeeker, Fade } from "react-awesome-reveal";
// import ReactPlayer from "react-player";

export const HomeHero = ({
  intro_subheader,
  intro_hello,
  intro_title,
  intro_intro,
  ctaLink,
  ctaText,
  cta2Link,
  cta2Text,
  intro_image,
  testimonial_image,
  testimonial_from,
  testimonial,
  logo_title,
  logos,
}) => {
  return (
    <React.Fragment>
      <header id="hero" className="hero hero_home hero-alt3 pb-0 bg-light">
        <div className="position-relative container-fluid">
          <div className="row contained-xl py-md-4 ">
            <div className="order-0 col-md-10 col-lg-5 col-xl-4-dnu offset-md-1  pt-lg-5 my-md-2 text-center text-lg-left floaty z-index-100">
              {/* <Fade> */}
              <div className="h6 font-italic mb-1">{intro_subheader}</div>
              {/* </Fade> */}
              <h1 className="display-4 mb-2">
                {/* <Fade delay={100}> */}
                <span className="d-block text-secondary">{intro_hello}</span>
                {/* </Fade> */}
                {/* <Fade top delay={400}> */}
                <span
                  className="d-block p-0"
                  dangerouslySetInnerHTML={{
                    __html: intro_title,
                  }}
                />
                {/* </Fade> */}
              </h1>

              {/* <Fade delay={400}> */}
              <div
                className="mb-3"
                dangerouslySetInnerHTML={{
                  __html: intro_intro,
                }}
              />
              {/* </Fade> */}
              {/* <AttentionSeeker effect="pulse" delay={600}> */}
              {/* <Fade delay={600}> */}
              <div>
                {ctaLink && (
                  <Link
                    to={ctaLink}
                    className="btn btn-danger mt-1 mt-3 btn-lg main-cta ml-2 ml-lg-0 mr-2"
                  >
                    {ctaText}
                  </Link>
                )}
                {cta2Link && (
                  <>
                    {/* <div className="d-block d-md-none"></div> */}
                    <Link
                      to={cta2Link}
                      className="btn btn-outline-danger mt-1 mt-3 btn-lg ml-2 ml-lg-0 main-cta mr-2"
                    >
                      {cta2Text}
                    </Link>
                  </>
                )}
              </div>
              {/* </Fade> */}
              {/* </AttentionSeeker> */}
            </div>
            <div className="order-2 order-lg-1 col-lg-5 col-xl-6-dnu offset-lg-05-dnu">
              <div className="hero-systemimage floaty mt-2 position-relative home_testimonial_container">
                <div className="floaty" data-scroll-speed-y="-10">
                  {intro_image && (
                    <>
                      {/* <Fade delay={400}> */}
                      {/* <div className="demo-video-embed">
                        <ReactPlayer
                          url={`https://vimeo.com/698221855/ee91300c6b`}
                          // vimeoConfig={{
                          //   iframeParams: { background: true, fullscreen: 0 },
                          // }}
                          className={`demo-video-embed-item shadow-lg rounded-lg`}
                          width="100%"
                          height="100%"
                          volume={0}
                          controls={false}
                          playsinline={true}
                          playIcon={
                            <i class="far fa-play-circle text-secondary h1 "></i>
                          }
                          muted={true}
                          light={false}
                          playing={true}
                          loop={true}
                        />
                      </div> */}
                      <Image
                        image={intro_image}
                        loading="eager"
                        alt="System screenshot"
                        className="img-fluid"
                      />
                      {/* </Fade> */}
                    </>
                  )}
                </div>
                {/* <Fade delay={400}> */}
                <div
                  className="shadow bg-light home_testimonial floaty"
                  data-scroll-speed-y="-45"
                >
                  <div className="image rounded-circle">
                    <Image
                      image={testimonial_image}
                      alt={testimonial_from}
                      className="rounded-circle"
                    />
                  </div>

                  <blockquote>
                    <p className="quote mb-0">{testimonial}</p>
                    <div className="text-warning mb-3">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </div>
                    <p className="text-secondary h6">{testimonial_from}</p>
                  </blockquote>
                </div>
                {/* </Fade> */}
              </div>
            </div>

            {logos && (
              <div className="order-1 order-lg-2 col-lg-12 mt-lg-5">
                {/* <Fade delay={500}> */}
                <section
                  id="partners"
                  className="text-md-right text-center container px-0 pt-lg-5"
                >
                  <h2 className="h6 mt-5 mb-3 text-center text-uppercase">
                    {logo_title}
                  </h2>
                  <div className="row justify-content-center mt-4">
                    {logos.map(function (logo, i) {
                      return (
                        <div className="col-2 align-content-center" key={"partner" + i}>
                          <div className="px-lg-3">
                            <Image
                              image={logo}
                              backgroundColor="transparent"
                              placeholder="blurred"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </section>
                {/* </Fade> */}
              </div>
            )}
          </div>
        </div>
      </header>
      <div id="afterheader"></div>
    </React.Fragment>
  );
};

export default HomeHero;
